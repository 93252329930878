









































































import Vue from 'vue';

import { mapActions } from 'vuex';

import { subscriptionActions } from '@/modules/subscription/store/names';

export default Vue.extend({
    name: 'CreateNewSubscription',

    data() {
        return {
            dialog: false,
            subscribe: {
                currency: '',
                id: null,
                monthly_price: null,
                num_months: null,
                num_years: null,
                price: null,
                limit_dreams: null,
            },
        };
    },

    methods: {
        ...mapActions({
            createSubscribesApi: subscriptionActions.createSubscribesApi,
        }),

        createSubscription() {
            this.createSubscribesApi(this.subscribe);
            for (const value in this.subscribe) {
                this.subscribe[value] = null;
            }
        },
    },
});
